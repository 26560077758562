import { Col, Image, message, Radio, Row, Space } from 'antd';
import React, { useEffect } from "react";
import * as http from '../../http';
import darkImage from './dark.png';
import classes from './UserTheme.module.css';
import whiteImage from './white.png';
function UserThemeMode() {
    let newData = {};
    const theme = JSON.parse(localStorage.getItem("theme"));
    const clss = theme === "Dark" ? classes.whiteclss : classes.blackclss;
    
    const [checkId, setCheckId] = React.useState();

    const handleChange = async () => {
        var themes = JSON.parse(localStorage.getItem("theme"));

        if(themes === "Dark")
            themes = "White";
        else
            themes = "Dark";

        var vlu = JSON.stringify(themes);
        localStorage.setItem("theme", vlu);
        
        newData['color']=themes;
        
        if(!checkId){
            pushData();
        }else{
            putData();
        }
    }

    useEffect(() => {
        get_user_theme();
    }, [checkId]);

    const pushData = ()=>{
        http.post('/api/user_theme/', newData).then((response) => {
        if (response.status == 201) {
            message.success('mode change and save successfully');
            window.location.reload(true);
        }
        }).catch((err) => {
            message.error('Color Save Failed');
            console.log(err);
        });
    }

    const get_user_theme = () =>{
        http.get(`/api/user_theme/`).then((resp)=>{
            // console.log("response : ", resp.data);
            if(resp?.data?.user_id != null){
                newData['id']=resp?.data?.id;
                newData['color']=resp?.data?.color;
                newData['user_id']=resp?.data?.user_id;
                
                setCheckId(true);
            }
            else{
                setCheckId(false);
            }
          }).catch((err)=>{
            console.log(err);
        })
    }

    const putData = () => {
        http.put(`/api/user_theme/`, newData)
        .then((response) => {
        if (response.status == 200) {
            message.success('mode change and save successfully');
            window.location.reload(true);
        }
        }).catch((err) => {
            message.error('Color Update Failed');
            console.log(err);
        });
    }

    return (

        <div className={classes.custom}>  
            <Space direction='horizontal' size={4} >
                <Row gutter={[48, 48]}>
                    <Radio.Group value={theme}>
                        <Space direction='vertical'>
                            <Col span={24}>
                                <Radio value={"White"} onChange={handleChange} style={{marginRight:"400px"}}><h2 className={clss}>Light Theme</h2></Radio>
                            </Col>

                            <Col span={24}>
                                <Image src={whiteImage} width={400} height={200} />
                            </Col>
                        </Space>

                        <Space direction='vertical'>
                            <Col span={24}>
                                <Radio value={"Dark"} onChange={handleChange}><h2 className={clss}>Dark Theme</h2></Radio>
                            </Col>

                            <Col span={24}>
                                <Image src={darkImage} width={400} height={200} />
                            </Col>
                        </Space>
                    </Radio.Group>
                </Row>
            </Space>

        </div>
    );
}

export default UserThemeMode;
