export const SearchFormMapper = [
    {
        label: '',
        name: 'model',
        type: 'input',
        rules: [],
        placeholder: 'Model',
    },
    {
        label: '',
        name: 'manufacturer',
        type: 'select',
        url:"/api/manufacturer/manufacturer_combo",
        rules: [],
        placeholder: 'Manufacturer Name',
    },
   
]