export const SearchFormMapper = [
    {
        label: '',
        name: 'model.like',
        type: 'input',
        rules: [],
        placeholder: 'Model',
    },
    {
        label: '',
        name: 'manufacturer',
        type: 'select',
        url: '/api/manufacturer/manufacturer_combo/',
        rules: [],
        placeholder: 'Manufacturer Name',
    },
    // {
    //     label: 'Test-3',
    //     name: 'test2',
    //     type: 'input',
    //     rules: [],
    //     placeholder: 'Test-3',
    // }
]