import { EditOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Space, Popconfirm, message } from 'antd';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import BaseList from "../../containers/BaseList/BaseList";
import { columnMapper } from './columnMapper';
import { SearchFormMapper } from './SearchFormMapper';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
const copy = <FontAwesomeIcon icon={faCopy}/>

class wlListPage extends BaseList {
getColumns(){
  const themeClass = JSON.parse(localStorage.getItem("theme")) === 'White' ? 'whiteedit_pen' : 'edit_pen';
  const deleteClass = JSON.parse(localStorage.getItem("theme")) === 'White' ? 'whitedelete_pen' : 'delete_pen';

  return [
    ...columnMapper,
    {
      title: 'Action',
      key: 'action',
      fixed:'right',
      render: (record) => (
        <Space size="middle">
        <Link 
          key='editButton'
          className={themeClass}
          to={`/wl_equipments/edit/${record.id}`}
        >
          <EditOutlined />
        </Link>
        <Link 
          key='duplicate'
          className={themeClass}
          to={`/wl_equipments/duplicate/${record.id}`}
        >
          {copy}
        </Link>
        <Popconfirm okType='default' title="Are sure to Delete?" okText="Yes" onConfirm={() => this.handleDelete(record)}>
              <Button 
                  key='deleteButton'
                  className={deleteClass}
                  icon={<DeleteOutlined />}
              />
            </Popconfirm>
        </Space>
      )
    }
  ];
}

getDataUrl(){
  return '/api/wl_comm_equipments/'; 
}
handleDelete(record){
  axios({
    url: `/api/wl_comm_equipments/${record.id}`,
    method: 'delete',
    type: 'json',
    }).then(res => {
      message.success("Deleted Successfully")
      this.fetch()
    
  }).catch((err) => {
    message.error('Delete Failed');
  });

}
getPageConfig(){
  return {
    pageTitle:'Equipments List',
    pageButtons: [
      <Button
        key='equipment-add'
        className={'create_btn'}
        onClick={()=>this.props.history.push('/wl_equipments/add')}
        type="primary"
        size="small"
        icon={<PlusOutlined />}
      >
          Add Equipment
      </ Button>
    ]
  }
}

getSearchFields(){
  return SearchFormMapper;
}
}
const mapStateToProps = (state) => ({
    auth: state.auth
})
export default connect(mapStateToProps)(withRouter(wlListPage));
