import { DoubleLeftOutlined } from '@ant-design/icons';
import { faCloudMoon, faCloudSunRain, faCogs, faPlus, faRainbow, faSortAmountDown, faTools } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Col, Row, Tabs } from 'antd';
import 'antd/dist/antd.min.css';
import React, { useEffect, useState } from 'react';
import classes from './Settings.module.css';

import * as http from '../../http';

const fatools = <FontAwesomeIcon icon={faTools} />;
const dayTimeIcon = <FontAwesomeIcon icon={faCloudMoon} />
const ionosphereIcon = <FontAwesomeIcon icon={faRainbow} />
const seasonIcon = <FontAwesomeIcon icon={faCloudSunRain} />
const performanceAttIcon = <FontAwesomeIcon icon={faSortAmountDown} />
const fontplusicon = <FontAwesomeIcon icon={faPlus} />
const faCogsicon = <FontAwesomeIcon icon={faCogs} />

const { TabPane } = Tabs;

export const DayTimeSettings = (props) => {
    let leftButton = "", ThemeChangeClass = "", ThemeInputDate = "", ThemeInputDateButton = "", ThemeCardData = "", ThemeTitle = "", ThemeDtSettingsLeft = "", ThemeDtSettingsLeftHeader = "", leftHeader = "";

    const mode = JSON.parse(localStorage.getItem("theme"));

    if(mode == 'White'){
        ThemeChangeClass = classes.whitetabpane_tab;
        ThemeInputDate = classes.whiteinput_date;
        ThemeInputDateButton = classes.whiteinput_date_button;
        ThemeCardData = classes.whitecardContentData;
        ThemeTitle = classes.whitetitle;
        ThemeDtSettingsLeft = classes.whitedt_setting_left;
        ThemeDtSettingsLeftHeader = classes.whitedt_setting_left_header;
        leftButton = classes.whitedt_setting_left_button;
        leftHeader = classes.whitedt_setting_left_header_title;
    }
    else
    {
        ThemeChangeClass = classes.tabpane_tab;
        ThemeInputDate = classes.input_date;
        ThemeInputDateButton = classes.input_date_button;
        ThemeCardData = classes.cardContentData;
        ThemeTitle = classes.title;
        ThemeDtSettingsLeft = classes.dt_setting_left;
        ThemeDtSettingsLeftHeader = classes.dt_setting_left_header;
        leftButton = classes.dt_setting_left_button;
        leftHeader = classes.dt_setting_left_header_title;
    }

    const [data, setData] = useState([]);
    const [detailData, setDetailData] = useState();
    const [search,setSearch]=React.useState({
        year: '',
        month:''

    })
    useEffect(() => {
        // axios({
        //     url: '/api/month_wise_day_time',
        //     method: 'get',
        //     type: 'json',
        // }).then(data => {
        //     setData(data.data);
        //     setDetailData(data.data[0]);
        // });

        http.get(`api/month_wise_day_time`).then((data)=>{
            setData(data.data);
            setDetailData(data.data[0]);
          }).catch((err)=>{
            console.log(err);
          })

    }, [setData, props.reload]);

    const handlePlusClick = () => {
        props.setModalWidth(800);
        props.setModalVisible(true);
        props.setsettingsName('day_time_settings');
    }

    const handleSearch = (label,value) =>{
        setSearch({label: value?.value})
    }
    
    return (
        <>
            <TabPane tab={<><span>{dayTimeIcon}</span><p>Day Time Settings</p></>} key="2" icon={<DoubleLeftOutlined />}
                className={classes.content}>
                <div className={ThemeChangeClass}>
                    <Row className={classes.header}>

                        <Col span={20}>
                            <h4>Day Time Settings</h4>
                        </Col>
                        
                        {/* this button has no activity so this comment-out */}
                        {/* <Col push={2}>
                            <Button className={classes.button_header} icon={<EditOutlined />}>Update</Button>
                        </Col> */}
                    </Row>
                    <Row>
                        <Col className={ThemeDtSettingsLeft} span={7}>
                            <Row className={ThemeDtSettingsLeftHeader}>
                                <Col span={12} className={leftHeader}>
                                    {/* <p>Daytime Settings</p> */}
                                </Col>
                                <Col span={5} className={leftButton} push={8}>
                                    <Button onClick={() => handlePlusClick()}> {fontplusicon} </Button> <span>...</span>
                                </Col>
                            </Row>
                            {/* <Form className={classes.search_form}>
                                <Row className={classes.year_input_row}>

                                    <Col span={10} pull={1}>
                                        <div className={ThemeInputDate}>
                                            <Row className={classes.year_input}>
                                                <SelectField 
                                                    placeholder="Year"
                                                    name="year"
                                                    url="api/combo/year"
                                                    // onSelectChange={(e)=>handleSearch('year',e)}
                                                    // value={search.year}
                                                /></Row>
                                            <Row className={classes.month_input}>
                                                <SelectField 
                                                    placeholder="Month"
                                                    name="month"
                                                    url="api/combo/month"
                                                    // value={search.month}
                                                    // onSelectChange={(e)=>handleSearch('month',e)}
                                                /></Row>
                                        </div>
                                    </Col>

                                    <Col className={ThemeInputDateButton} span={4}>
                                        <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>
                                        </Button>
                                        <Button
                                            style={{ margin: '0 8px' }}
                                            onClick={() => {
                                                form.resetFields();
                                            }}
                                        >
                                            Clear
                                        </Button>
                                    </Col>
                                </Row>
                                <br />
                                <Row justify='space-between'>

                                </Row>
                            </Form> */}
                            <div className={classes.card_btn}>
                                {data ? data.map((key, index) => <Card className={classes.card}
                                    key={`${key.year}_${key.month}`}
                                    onClick={() => setDetailData(data[index])}>{`Day Time Settings For ${key.year}_${key.month}`} </Card>) : null}
                            </div>
                           
                        </Col>
                        {detailData?
                        <Col className={classes.dt_setting_right} span={17}>
                            <Row className={classes.dt_setting_right_header}>Settings Details</Row>

                            <Row className={ThemeTitle}>
                                {`Daytime Settings For ${detailData?.year}_${detailData?.month}`}
                            </Row>

                            <br />
                            <Row className={classes.main_table}>
                                <Col span={12} className={ThemeCardData} style={{ borderRight: '0px' }}>
                                    <Row
                                        className={[classes.justify_content_around_between, classes.table_row].join(' ')}>
                                        <Col span={6}>Day Time</Col>
                                        <Col span={6}>
                                            <Row>From</Row>
                                            <Row
                                                className={classes.table_different_col_txt}>{detailData ? detailData["Day"] ? detailData["Day"]["from_time"] : '' : ''}</Row>
                                        </Col>
                                        <Col span={6}>
                                            <Row>To</Row>
                                            <Row
                                                className={classes.table_different_col_txt}>{detailData ? detailData["Day"] ? detailData["Day"]["to_time"] : '' : ''}</Row>
                                        </Col>
                                        <Col span={6}>
                                            <Row>Duration</Row>
                                            <Row
                                                className={classes.table_different_col_txt}>{detailData ? detailData["Day"] ? detailData["Day"]["duration"] : '' : ''} Hour</Row>
                                        </Col>
                                    </Row>
                                    <Row
                                        className={[classes.justify_content_around_between, classes.table_row].join(' ')}>
                                        <Col span={6}>Dusk time</Col>
                                        <Col span={6}>
                                            <Row>From</Row>
                                            <Row
                                                className={classes.table_different_col_txt}>{detailData ? detailData["Dusk"] ? detailData["Dusk"]["from_time"] : '' : ''}</Row>
                                        </Col>
                                        <Col span={6}>
                                            <Row>To</Row>
                                            <Row
                                                className={classes.table_different_col_txt}>{detailData ? detailData["Dusk"] ? detailData["Dusk"]["to_time"] : '' : ''}</Row>
                                        </Col>
                                        <Col span={6}>
                                            <Row>Duration</Row>
                                            <Row
                                                className={classes.table_different_col_txt}>{detailData ? detailData["Dusk"] ? detailData["Dusk"]["duration"] : '' : ''} Hour</Row>
                                        </Col>
                                    </Row>
                                    <Row
                                        className={[classes.justify_content_around_between, classes.table_row_no_border].join(' ')}>
                                        <Col span={6}>Evening time</Col>
                                        <Col span={6}>
                                            <Row>From</Row>
                                            <Row
                                                className={classes.table_different_col_txt}>{detailData ? detailData["Evening"] ? detailData["Evening"]["from_time"] : '' : ''}</Row>
                                        </Col>
                                        <Col span={6}>
                                            <Row>To</Row>
                                            <Row
                                                className={classes.table_different_col_txt}>{detailData ? detailData["Evening"] ? detailData["Evening"]["to_time"] : '' : ''}</Row>
                                        </Col>
                                        <Col span={6}>
                                            <Row>Duration</Row>
                                            <Row
                                                className={classes.table_different_col_txt}>{detailData ? detailData["Evening"] ? detailData["Evening"]["duration"] : '' : ''} Hour</Row>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={12} className={ThemeCardData}>
                                    <Row
                                        className={[classes.justify_content_around_between, classes.table_row].join(' ')}>
                                        <Col span={6}>Night time</Col>
                                        <Col span={6}>
                                            <Row>From</Row>
                                            <Row
                                                className={classes.table_different_col_txt}>{detailData ? detailData["Night"] ? detailData["Night"]["from_time"] : '' : ''}</Row>
                                        </Col>
                                        <Col span={6}>
                                            <Row>To</Row>
                                            <Row
                                                className={classes.table_different_col_txt}>{detailData ? detailData["Night"] ? detailData["Night"]["to_time"] : '' : ''}</Row>
                                        </Col>
                                        <Col span={6}>
                                            <Row>Duration</Row>
                                            <Row
                                                className={classes.table_different_col_txt}>{detailData ? detailData["Night"] ? detailData["Night"]["duration"] : '' : ''} Hour</Row>
                                        </Col>
                                    </Row>
                                    <Row
                                        className={[classes.justify_content_around_between, classes.table_row].join(' ')}>
                                        <Col span={6}>Dawn time</Col>
                                        <Col span={6}>
                                            <Row>From</Row>
                                            <Row
                                                className={classes.table_different_col_txt}>{detailData ? detailData["Dawn"] ? detailData["Dawn"]["from_time"] : '' : ''}</Row>
                                        </Col>
                                        <Col span={6}>
                                            <Row>To</Row>
                                            <Row
                                                className={classes.table_different_col_txt}>{detailData ? detailData["Dawn"] ? detailData["Dawn"]["to_time"] : '' : ''}</Row>
                                        </Col>
                                        <Col span={6}>
                                            <Row>Duration</Row>
                                            <Row
                                                className={classes.table_different_col_txt}>{detailData ? detailData["Dawn"] ? detailData["Dawn"]["duration"] : '' : ''} Hour</Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                        </Col>
                        : ''}
                    </Row>
                </div>
            </TabPane>
        </>
    )
}