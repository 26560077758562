import { Button, Form, Row } from 'antd';
import axios from 'axios';
import { useEffect } from 'react';
import { InputField } from '../../components/Fields/InputField';
import classes from './AddPage.module.css';
import "../../App.css";

export const AddPage = (props) => {
    const [form] = Form.useForm();
    // const [errors, setError]= React.useState({})
    useEffect(() => {
        if (props.editUrl) {
            axios({
                url: props.editUrl,
                method: 'get',
                type: 'json',
            }).then(data => {
                form.setFieldsValue({ 'id': data.data.id });
                form.setFieldsValue({ 'name': data.data.name });
                form.setFieldsValue({ 'code': data.data.code });
            });
        }
    }, []);
    const onFinish=(values)=>{
        const PostData = {Manufacturer: {}};
        PostData['Manufacturer']['id'] = values.id;
        PostData['Manufacturer']['name'] = values.name;
        PostData['Manufacturer']['code'] = values.code;
        return props.onFinish(PostData);
    }
    return (
        <Form style={{ color: "white" }} onFinish={onFinish} form={form}>
            <div className={classes.modalContent}>
            <Row>
            <Form.Item 
            name='id'
            label={<p className={"formLabel"}>ID</p>}
            labelCol={{span: 24}}
            hidden>
            <InputField />
            </Form.Item>
            <Form.Item
            name='name'
            label={<p className={"formLabel"}>Name</p>}
            labelCol={{span: 24}}
            validateStatus={props.errors?.name?.validateStatus}
            help={props.errors?.name?.errorMsg || null}
            required={true}
            >
                <InputField />
            </Form.Item>
            </Row>
            <Row>
            <Form.Item name='code' label={<p className={"formLabel"}>Code</p>} labelCol={{span: 24}}>
                <InputField />
            </Form.Item>
            </Row>
            </div>
            <div className={[classes.footer, 'button_row'].join(' ')}>
            <Button type="primary" htmlType="submit">
                    {props.editUrl? 'Update':'Add'}
            </Button>
            </div>
        </Form>
    )
}