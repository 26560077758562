import { PlusOutlined } from '@ant-design/icons';
import { faCloudMoon, faCloudSunRain, faCogs, faPlus, faRainbow, faSortAmountDown, faTools } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Col, Row, Table, Tabs } from 'antd';
import 'antd/dist/antd.min.css';
import React, { useEffect, useState } from 'react';
import * as http from '../../http';
import classes from './Settings.module.css';

const fatools = <FontAwesomeIcon icon={faTools} />;
const dayTimeIcon = <FontAwesomeIcon icon={faCloudMoon} />
const ionosphereIcon = <FontAwesomeIcon icon={faRainbow} />
const seasonIcon = <FontAwesomeIcon icon={faCloudSunRain} />
const performanceAttIcon = <FontAwesomeIcon icon={faSortAmountDown} />
const fontplusicon = <FontAwesomeIcon icon={faPlus} />
const faCogsicon = <FontAwesomeIcon icon={faCogs} />

const { TabPane } = Tabs;

export const IonoSphereSettings = (props) => {
    const ThemeChangeClass = JSON.parse(localStorage.getItem("theme")) === 'White' ? classes.whitetabpane_tab : classes.tabpane_tab;
    const ThemeInputDate = JSON.parse(localStorage.getItem("theme")) === 'White' ? classes.whiteinput_date : classes.input_date;
    const ThemeInputDateButton = JSON.parse(localStorage.getItem("theme")) === 'White' ? classes.whiteinput_date_button : classes.input_date_button;
    const ThemeTitle = JSON.parse(localStorage.getItem("theme")) === 'White' ? classes.whitetitle : classes.title;
    const ThemeDtSettingsLeft = JSON.parse(localStorage.getItem("theme")) === 'White' ? classes.whitedt_setting_left : classes.dt_setting_left;
    const ThemeDtSettingsLeftHeader = JSON.parse(localStorage.getItem("theme")) === 'White' ? classes.whitedt_setting_left_header : classes.dt_setting_left_header;
    const DtSettingLeftButton = JSON.parse(localStorage.getItem("theme")) === 'White' ? classes.whitedt_setting_left_button : classes.dt_setting_left_button;

    const scroll = {x: 800}
    const columns = [
        {
            title: 'Duration',
            dataIndex: 'day_time_text',
        },
        {
            title: 'Max Height (Km)',
            dataIndex: 'max_height',
        },
        {
            title: 'Min Height (Km)',
            dataIndex: 'min_height',
        }, {
            title: 'electron_density (e Å-3)',
            dataIndex: 'electron_density',
        }

    ]

    const [data, setData] = useState([]);
    const [detailData, setDetailData] = useState({});
    const [isLoading, setLoading] = useState(true);
    const [reLoad, setReload] = useState(props.reload)
    const [search,setSearch]=React.useState({
        year: '',
        month:''

    })
    useEffect(() => {
        http.get(`api/ionosphere_settings`).then((data)=>{
            setData(data.data);
            setDetailData(data.data[Object.keys(data.data)[0]]);
            setLoading(false);
          }).catch((err)=>{ 
            console.log(err);
          })
    }, [setData, props.reload]);

    const handlePlusClick = () => {
        props.setModalWidth(1000);
        props.setModalVisible(true);
        props.setsettingsName('ionosphere_settings');
    }
    const handleSearch = (label,value) =>{
        setSearch({label: value?.value})
    }
    const getTitle=()=>{
        if(detailData && !_.isEmpty(detailData)){
            return `Ionosphere Settings For  ${detailData[Object.keys(detailData)[0]][0].year}_${detailData[Object.keys(detailData)[0]][0].month}`
        }
        return '';
    }
    return (
        <TabPane tab={<><span>{ionosphereIcon}</span><p>Ionoshpere Settigns</p></>} key="4" className={classes.content}>
            <div className={ThemeChangeClass}>
                <Row className={classes.header}>
                    <Col span={20}>
                        <h4>Ionoshpere's Settings</h4>
                    </Col>
                    <Col push={2}>

                    </Col>
                </Row>
                <Row>
                    <Col className={ThemeDtSettingsLeft} span={7}>
                        <Row className={ThemeDtSettingsLeftHeader}>
                            <Col span={12} className={classes.dt_setting_left_header_title}>
                                {/* <p>Ionoshpere's Settings</p> */}

                            </Col>
                            <Col span={5} className={DtSettingLeftButton} push={8}>
                                <Button onClick={() => handlePlusClick()}> <PlusOutlined /> </Button>
                            </Col>
                        </Row>
                        {/* <Form className={classes.search_form}>
                            <Row className={classes.year_input_row}>
                                <Col span={10}>
                                    <div className={ThemeInputDate}>
                                        <Row className={classes.year_input}>
                                            <SelectField 
                                                placeholder="Year" 
                                                name="year"
                                                url="api/combo/year"
                                                // onSelectChange={(e)=>handleSearch('year',e)}
                                                // value={search.year} 
                                            />
                                        </Row>
                                        <Row className={classes.month_input}>
                                            <SelectField 
                                                placeholder="Month"
                                                name="month"
                                                url="api/combo/month"
                                                // onSelectChange={(e)=>handleSearch('month',e)}
                                                // value={search.month}
                                            />
                                        </Row>
                                    </div>
                                </Col>
                                <Col className={ThemeInputDateButton} span={6} style={{ textAlign: 'right' }}>
                                    <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>
                                    </Button>
                                    <Button
                                        style={{ margin: '0 8px' }}
                                        onClick={() => {
                                            form.resetFields();
                                        }}
                                    >
                                        Clear
                                    </Button>
                                </Col>
                            </Row>
                            <br />

                        </Form> */}

                        <div className={classes.card_btn}>
                            {data ? Object.keys(data).map((key) => <Card className={classes.card} key={key}
                                onClick={() => setDetailData(data[key])}>{`Ionosphere Settings For ${key}`} </Card>) : null}
                        </div>


                    </Col>
                    <Col span={17} className={classes.colscroll}>

                        <Row className={classes.dt_setting_right_header}>Settings Details</Row>
                        <Row className={ThemeTitle}>
                            {getTitle()}
                                {/*{detailData? `Ionosphere Settings For ${detailData[Object.keys(detailData)?[0]]?.[0]?.year: ''}_${detailData?[Object.keys(detailData)?.[0]]?.[0]?.month:''}`}*/}
                        </Row>


                        {detailData ? Object.keys(detailData).map((key) => <Table className={[classes.ionosphare_table, 'ionoshpare_set_table'].join(' ')} key={key} columns={columns} scroll={scroll}
                            title={() => <h3
                            style={{ color: "white" }}>{key}</h3>}
                            dataSource={detailData[key]}
                            loading={isLoading} 
                            />) : null}
                    </Col>
                </Row>
            </div>

        </TabPane>
    )
}